import { HedisMetricVisitStatusEnum } from '../../Enum/HedisMetricVisitStatusEnum';
import { HedisMetricsTypeEnum } from '../../Enum/HedisMetricsTypeEnum';

export default interface IHedisMetricsVisit {
    Id: string;
    DateLastMet: Date;
    EditedTime: Date;
    HedisMetricType: HedisMetricsTypeEnum;
    IsExpired: boolean;
    IsMetAtThisVisit: boolean;
    ProviderId: string;
    Status: HedisMetricVisitStatusEnum;
}

export class HedisMetricsVisit implements IHedisMetricsVisit {
    Id: null;
    DateLastMet: null;
    EditedTime: null;
    HedisMetricType: null;
    IsExpired: false;
    IsMetAtThisVisit: false;
    ProviderId: string;
    Status: null;

    constructor() {
        this.Id = null;
        this.DateLastMet = null;
        this.EditedTime = null;
        this.HedisMetricType = null;
        this.IsExpired = false;
        this.IsMetAtThisVisit = false;
        this.ProviderId = '';
        this.Status = null;
    }
}
