import {
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Grid,
    Paper,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Popover,
    Tooltip,
    Divider,
} from '@mui/material';
import moment from 'moment';
import { HedisMetricVisitStatusEnum } from '../../../Enum/HedisMetricVisitStatusEnum';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    hedisMetricsList,
    setConfirmationIsOpen,
    setHedisMetricVisit,
    setMetricName,
    setShowPreviousCharts,
    submitHedisMetricVisit,
} from '../../../store/memberDemographicsDetails.slice';
import { getDefaultDiagnosis } from '../../../store/encounter.slice';
import Authorization from '../../../utils/Authorization';
import { useState, useEffect } from 'react';
import phoneFormat from '../../../utils/phoneFormat';
import { HedisMetricsVisit } from '../../../Models/HedisMetrics/HedisMetricsVisit.model';
import MemberPreviousChartsModal from '../MemberPreviousChartsModal/MemberPreviousChartsModal';

const HedisConfirmationDialog = (props) => {
    const { hedisMetricVisit, metricName, onClose, open, onConfirm, onCancel } = props;
    const visited = hedisMetricVisit?.IsMetAtThisVisit ? 'MARK' : 'UNMARK';
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" p={2}>
                    Are you sure you want to <b>{`${visited} ${metricName}`}</b> as <b>Visited</b>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                <Button className="button-120" variant="contained" onClick={onConfirm} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const MemberInformation = (props) => {
    const { memberId } = props;
    const { chartDetails, confirmationIsOpen, hedisMetricVisit, metricName, previousMemberCharts } = useSelector(
        (state: RootState) => state.memberDemographicsDetails
    );
    const { memberDefaultDiagnosis } = useSelector((state: RootState) => state.encounter);
    const { memberHealthPlans } = useSelector((state: RootState) => state.memberDetail);
    const auth = new Authorization();
    const dispatch = useDispatch();

    const [diagnosisAnchorEl, setDiagnosisAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClickDiagnosis = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDiagnosisAnchorEl(event.currentTarget);
    };

    const handleCloseDiagnosis = () => {
        setDiagnosisAnchorEl(null);
    };

    const diagnosisOpen = Boolean(diagnosisAnchorEl);
    const diagnosisId = diagnosisOpen ? 'simple-popover' : undefined;

    const closeConfirmationDialog = () => {
        dispatch(setConfirmationIsOpen(false));
    };

    const cancelHedisMetricVisit = (e) => {
        dispatch(setConfirmationIsOpen(false));
    };

    const onCheckHandler = (event, metric) => {
        event.preventDefault();
        let currentHedisMetricVisit = { ...chartDetails[event.target.name] };
        if (!currentHedisMetricVisit || currentHedisMetricVisit === undefined) {
            currentHedisMetricVisit = new HedisMetricsVisit();
        }
        currentHedisMetricVisit.DateLastMet = moment().format('YYYY-MM-DDTHH:mm:ss');
        currentHedisMetricVisit.HedisMetricType = metric.type;
        currentHedisMetricVisit.IsMetAtThisVisit = event.target.checked;
        currentHedisMetricVisit.ProviderId = auth.UserId;

        dispatch(setMetricName(hedisMetricsList.find((metric) => metric.name === event.target.name)?.label));
        dispatch(setHedisMetricVisit(currentHedisMetricVisit));
        dispatch(setConfirmationIsOpen(true));
    };

    const showStar = (team) => {
        if (!team) return false;
        if (team?.Id === chartDetails?.Team?.Id) return true;
        return false;
    };

    const fontColor = (metric) => {
        if (metric?.IsExpired) {
            return 'error.main';
        } else if (
            metric?.Status === HedisMetricVisitStatusEnum.ControlledAndPending ||
            metric?.Status === HedisMetricVisitStatusEnum.NotControlledAndPending
        ) {
            return 'primary.main';
        } else if (
            metric?.Status === HedisMetricVisitStatusEnum.ControlledAndAccepcted ||
            metric?.Status === HedisMetricVisitStatusEnum.NotControlledAndAccepcted
        ) {
            return 'success.main';
        } else if (
            metric?.Status === HedisMetricVisitStatusEnum.ControlledAndRejected ||
            metric?.Status === HedisMetricVisitStatusEnum.NotControlledAndRejected
        ) {
            return 'warning.main';
        } else {
            return 'rgba(0,0,0, 0.4)';
        }
    };

    const handleViewPreviousCharts = () => {
        dispatch(setShowPreviousCharts(true));
    };

    useEffect(() => {
        dispatch(getDefaultDiagnosis(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <HedisConfirmationDialog
                open={confirmationIsOpen}
                onClose={closeConfirmationDialog}
                hedisMetricVisit={hedisMetricVisit}
                metricName={metricName}
                onCancel={cancelHedisMetricVisit}
                onConfirm={() => dispatch(submitHedisMetricVisit(memberId, hedisMetricVisit))}
            />
            <MemberPreviousChartsModal />
            <Box sx={{ backgroundColor: '#ddd', p: 1 }}>
                <FormGroup sx={{ marginRight: -1 }}>
                    <Grid container spacing={1}>
                        <Paper square elevation={3} sx={{ p: 2, display: 'flex', width: '100%' }}>
                            <Grid item xs={3}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                    <Typography variant="body2">Last Seen:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.LastCHPVisit ? moment(chartDetails?.LastCHPVisit).format('MM/DD/yyyy') : '-'}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Risk Score:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.RiskScore}
                                    </Typography>
                                </Stack>

                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Plan:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.PrimaryHealthPlan?.Name}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Insurance:</Typography>
                                    <Grid container columnGap={2} justifyContent={'flex-end'}>
                                        {memberHealthPlans.map((plan, index) => {
                                            return (
                                                <Grid item display={'flex'} key={index}>
                                                    <Typography textAlign={'right'} fontStyle={'italic'} variant="body2" marginRight={1}>
                                                        {plan.Priority ? plan.Priority : '-'}
                                                    </Typography>
                                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                        {plan?.Name ? plan?.Name : '-'}
                                                    </Typography>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={3}>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CC:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CCTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CCTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        NP:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.NPTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.NPTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        BH:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.BHTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.BHTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CL:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CLTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CLTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CHW:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CHWTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CHWTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={4}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                    <Typography variant="body2">Address:</Typography>
                                    <Stack justifyContent={'flex-end'} spacing={0}>
                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                            {`${chartDetails?.MainAddress?.Address1 ? chartDetails?.MainAddress?.Address1 : '-'}${
                                                chartDetails?.MainAddress?.Address2 ? ', ' + chartDetails?.MainAddress?.Address2 : ''
                                            } `}
                                        </Typography>
                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                            {`${chartDetails?.MainAddress?.City ? chartDetails?.MainAddress?.City : '-'}${
                                                chartDetails?.MainAddress?.State ? ', ' + chartDetails?.MainAddress?.State : ''
                                            } ${chartDetails?.MainAddress?.ZipCode ? chartDetails?.MainAddress?.ZipCode : ''}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {chartDetails.IsDifferMeetingAddress && (
                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                        <Typography variant="body2">Meeting Address:</Typography>
                                        <Stack justifyContent={'flex-end'} spacing={0}>
                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                {`${chartDetails?.MainMeetingAddress?.Address1 ? chartDetails?.MainMeetingAddress?.Address1 : '-'}${
                                                    chartDetails?.MainMeetingAddress?.Address2 ? ', ' + chartDetails?.MainMeetingAddress?.Address2 : ''
                                                } `}
                                            </Typography>
                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                {`${chartDetails?.MainMeetingAddress?.City ? chartDetails?.MainMeetingAddress?.City : '-'}${
                                                    chartDetails?.MainMeetingAddress?.State ? ', ' + chartDetails?.MainMeetingAddress?.State : ''
                                                } ${chartDetails?.MainMeetingAddress?.ZipCode ? chartDetails?.MainMeetingAddress?.ZipCode : ''}`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )}
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Phones:</Typography>
                                    <Grid container columnGap={2} justifyContent={'flex-end'}>
                                        {chartDetails?.Phones?.map((phone, index) => {
                                            return (
                                                <Grid key={index} item display={'flex'} color={phone.IsPrimary ? 'primary.main' : 'rgba(0,0,0,0.5)'}>
                                                    <Typography textAlign={'right'} variant="body2" marginRight={1}>
                                                        {phone.Textable ? 'Text -' : 'No Text -'}
                                                    </Typography>
                                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                        {phone.Number ? phoneFormat(phone.Number) : '-'}
                                                    </Typography>
                                                    {phone.IsPrimary && (
                                                        <Tooltip title="Primary Phone">
                                                            <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 1 }} />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Primary Language:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.PrimaryLanguage?.Name}
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={2}>
                                <Stack spacing={2}>
                                    <Button aria-describedby={diagnosisId} variant="outlined" onClick={handleClickDiagnosis} sx={{ width: '100%' }}>
                                        View Primary Diagnosis
                                    </Button>
                                    <Popover
                                        id={diagnosisId}
                                        open={diagnosisOpen}
                                        anchorEl={diagnosisAnchorEl}
                                        onClose={handleCloseDiagnosis}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{ style: { maxWidth: '400px' } }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            <Typography variant="body1" fontWeight={700}>
                                                Primary Diagnosis
                                            </Typography>
                                            <Typography variant="body2">
                                                {Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis?.Name
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Popover>
                                    <Button
                                        disabled={previousMemberCharts.length > 0 ? false : true}
                                        aria-describedby={diagnosisId}
                                        variant="outlined"
                                        onClick={handleViewPreviousCharts}
                                        sx={{ width: '100%' }}
                                    >
                                        View Previous Charts ({previousMemberCharts.length})
                                    </Button>
                                </Stack>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid container spacing={1} marginTop={1} justifyContent={'center'}>
                        {hedisMetricsList.map((metric, index) => {
                            return (
                                <Grid item p={1} textAlign={'center'} key={index}>
                                    <Paper elevation={3} sx={{ p: 1 }}>
                                        <Typography fontWeight={700} color={fontColor(chartDetails?.[metric.name])} variant="body1">
                                            {metric.label}
                                        </Typography>
                                        <Typography color={fontColor(chartDetails?.[metric.name])} variant="body2">
                                            Last Met:{' '}
                                            {chartDetails?.[metric.name]?.DateLastMet
                                                ? moment(chartDetails?.[metric.name]?.DateLastMet).format('MM/DD/yyyy')
                                                : '-'}
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={metric.name}
                                                    checked={chartDetails?.[metric.name]?.IsMetAtThisVisit ? true : false}
                                                    onClick={(e) => onCheckHandler(e, metric)}
                                                    sx={{ mb: 0, fontSize: '0.5rem!important' }}
                                                />
                                            }
                                            label="Met at this visit"
                                            labelPlacement="start"
                                            sx={{ mb: 0, fontSize: '0.5rem!important', color: fontColor(chartDetails?.[metric.name]) }}
                                        />
                                    </Paper>
                                </Grid>
                            );
                        })}
                        <Grid item xs={12}>
                            <Stack direction={'row'} spacing={2} sx={{ backgroundColor: 'white' }} justifyContent={'center'}>
                                <Typography variant="body2" fontWeight={'bold'} color={'error.main'}>
                                    Is Expired
                                </Typography>
                                <Typography variant="body2" fontWeight={'bold'} color={'primary.main'}>
                                    Controlled/Not Controlled and Pending
                                </Typography>
                                <Typography variant="body2" fontWeight={'bold'} color={'success.main'}>
                                    Controlled/Not Controlled and Accepted
                                </Typography>
                                <Typography variant="body2" fontWeight={'bold'} color={'warning.main'}>
                                    Controlled/Not Controlled and Rejected
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </FormGroup>
            </Box>
        </>
    );
};
export default MemberInformation;
