import { AnswerOptionsEnum } from '../Enum/AnswerOptionsEnum';
import { AudienceEnum } from '../Enum/AudienceEnum';
import { EducationStatusEnum } from '../Enum/EducationStatusEnum';
import { EducationTimeDurationEnum } from '../Enum/EducationTimeDurationEnum';
import { LevelOfTeachbackEnum } from '../Enum/LevelOfTeachbackEnum';
import { LevelOfUnderstandingEnum } from '../Enum/LevelOfUnderstandingEnum';
import { OnboardingMeansOfEngagementEnum } from '../Enum/OnboardingMeansOfEngagementEnum';
import { TimelineTypeEnum } from '../Enum/TimelineTypeEnum';
import { appointmentStatusEnums } from './constants';
import { CarePlanEnum } from '../Enum/CarePlanEnum';
import { MemberStatusEnum } from '../Enum/MemberStatusEnum';
export const appointmentStatusMap = {
    Unconfirmed: appointmentStatusEnums.find((x) => x.text === 'Unconfirmed'),
    Confirmed: appointmentStatusEnums.find((x) => x.text === 'Confirmed'),
    NoCallNoShow: appointmentStatusEnums.find((x) => x.text === 'No Call No Show'),
    Cancelled: appointmentStatusEnums.find((x) => x.text === 'Cancelled'),
    Completed: appointmentStatusEnums.find((x) => x.text === 'Completed'),
    ProviderUnavailable: appointmentStatusEnums.find((x) => x.text === 'Provider Unavailable'),
} as const;

export const appointmentTypeMap = {
    BehavioralHealth: {
        value: 21,
        text: 'Behavioral Health Appointment',
        abbrev: 'BHA',
    },
    CarePlanning: { value: 7, text: 'Care Planning', abbrev: 'CP' },
    CheckInCall: {
        value: 16,
        text: 'Check In Call',
        abbrev: 'P',
        preferredType: 3,
    },
    FollowUp: { value: 3, text: 'Follow Up', abbrev: 'F' },
    Initial: { value: 2, text: 'Initial', abbrev: 'I' },
    Onboarding: { value: 1, text: 'Onboarding', abbrev: 'O' },
    PCPAppointment: {
        value: 8,
        text: 'PCP Appointment',
        abbrev: 'PCP',
        preferredType: 19,
    },
    PhoneCall: { value: 4, text: 'Phone Call', abbrev: 'P', preferredType: 3 },
    ProviderLeave: { value: 24, text: 'Provider Leave', abbrev: 'PL' },
    SocialServices: { value: 6, text: 'Social Services', abbrev: 'S' },
    TeamMeeting: { value: 26, text: 'Team Meeting', abbrev: 'TM' },
    Telemedicine: {
        value: 17,
        text: 'Telemedicine',
        abbrev: 'T',
        preferredType: 3,
    },
    TransitionalCare: { value: 22, text: 'Transitional Care', abbrev: 'TC' },
    UrgentResponse: { value: 23, text: 'Urgent Response', abbrev: 'UR' },
    Rescheduled: { value: 25, text: 'Rescheduled', abbrev: 'R' },
    Integrated: {
        value: 32,
        text: 'Integrated Care Appointment',
        abbrev: 'IC',
    },
} as const;

export const dictionaryToArray = (dictionary) => {
    const items = [];
    for (const prop in dictionary) {
        items.push({ value: prop, label: dictionary[prop] });
    }
    return items;
};

export const placesOfService = [
    { value: '2', label: 'Phone' },
    { value: '2', label: 'Telemedicine' },
    { value: '11', label: 'Email' },
    { value: '11', label: 'Text Message' },
    { value: '11', label: 'Office Visit' },
    { value: '12', label: 'Home' },
    { value: '31', label: 'Skilled Nursing Facility' },
    { value: '32', label: 'Nursing Facility' },
];
export const requestTypeMap = {
    Medications: { value: 1, text: 'Medications' },
    Transportation: { value: 2, text: 'Transporation' },
    FollowUpAppointment: { value: 3, text: 'Follow Up Appointment' },
    DME: { value: 4, text: 'DME' },
    Housing: { value: 5, text: 'Housing' },
    MedicalRecords: { value: 6, text: 'Medical Records' },
    FoodAssistance: { value: 7, text: 'Food Assistance' },
    Referrals: { value: 8, text: 'Referrals' },
    RentalAssistance: { value: 9, text: 'Rental Assistance' },
    UtilityAssistance: { value: 10, text: 'Utility Assistance' },
    ClothingOrHouseholdItems: {
        value: 11,
        text: 'Clothing Or Household Items',
    },
    Dental: { value: 12, text: 'Dental' },
    TeleMonitoringEquipment: { value: 13, text: 'Telemonitoring Equipment' },
    Others: { value: 14, text: 'Others' },
    SDOH: { value: 15, text: 'PHQ9' },
    PHQ9: { value: 16, text: 'SDOH' },
    HraFollowUp: { value: 17, text: 'HRA Follow Up' },
    PreventativeHealthAdvice: { value: 18, text: 'Preventative Health Advice' },
    FuhFumFollowUp: { value: 19, text: 'FUHFUM Follow Up' },
    DemographicResearch: { value: 20, text: 'Demographic Research' },
    Hedis: { value: 21, text: 'Hedis' },
    HRAReferralToCHPFullProgram: { value: 22, text: 'HRA-Referral to CHP Full Program' },
    HRASchedulePCPVisit: { value: 23, text: 'HRA-Schedule PCP Visit' },
    HRAScheduleBHFollowUp: { value: 24, text: 'HRA-Schedule BH Follow-up' },
} as const;

export const findRequestType = (searchValue: number) => Object.values(requestTypeMap).find(({ value }) => value === searchValue);

export const OnboardingMeansOfEngagements = [
    { value: OnboardingMeansOfEngagementEnum.OutreachCall, label: OnboardingMeansOfEngagementEnum.OutreachCallDescription },
    { value: OnboardingMeansOfEngagementEnum.Letter, label: OnboardingMeansOfEngagementEnum.LetterDescription },
    { value: OnboardingMeansOfEngagementEnum.Email, label: OnboardingMeansOfEngagementEnum.EmailDescription },
    { value: OnboardingMeansOfEngagementEnum.Other, label: OnboardingMeansOfEngagementEnum.OtherDescription },
];

export const TimelineTypes = [
    { value: TimelineTypeEnum.Engagement, label: TimelineTypeEnum.EngagementDescription },
    { value: TimelineTypeEnum.Appointment, label: TimelineTypeEnum.AppointmentDescription },
    { value: TimelineTypeEnum.IntegratedCarePlan, label: TimelineTypeEnum.IntegratedCarePlanDescription },
    { value: TimelineTypeEnum.Assessments, label: TimelineTypeEnum.AssessmentsDescription },
    { value: TimelineTypeEnum.Documents, label: TimelineTypeEnum.DocumentsDescription },
    { value: TimelineTypeEnum.DemographicInfo, label: TimelineTypeEnum.DemographicInfoDescription },
    { value: TimelineTypeEnum.CareGivers, label: TimelineTypeEnum.CareGiversDescription },
    { value: TimelineTypeEnum.ClinicalData, label: TimelineTypeEnum.ClinicalDataDescription },
    { value: TimelineTypeEnum.MemberStatusChange, label: TimelineTypeEnum.MemberStatusChangeDescription },
    { value: TimelineTypeEnum.Request, label: TimelineTypeEnum.RequestDescription },
    { value: TimelineTypeEnum.PartTwoDataDownload, label: TimelineTypeEnum.PartTwoDataDownloadDescription },
    { value: TimelineTypeEnum.ClonedMember, label: TimelineTypeEnum.ClonedMemberDescription },
    { value: TimelineTypeEnum.ClosedMemberAccessed, label: TimelineTypeEnum.ClosedMemberAccessedDescription },
    { value: TimelineTypeEnum.OverrideCloseTransitionalCare, label: TimelineTypeEnum.OverrideCloseTransitionalCareDescription },
    { value: TimelineTypeEnum.TransitionalCare, label: TimelineTypeEnum.TransitionalCareDescription },
    { value: TimelineTypeEnum.BackgroundJob, label: TimelineTypeEnum.BackgroundJobDescription },
];

export const EducationOnDrinkingStatuses = [
    { value: AnswerOptionsEnum.Yes, label: AnswerOptionsEnum.YesDescription },
    { value: AnswerOptionsEnum.No, label: AnswerOptionsEnum.NoDescription },
    { value: AnswerOptionsEnum.NotApplicable, label: AnswerOptionsEnum.NotApplicableDescription },
];

export const Audiences = [
    { Id: AudienceEnum.Member, Name: AudienceEnum.MemberDescription },
    { Id: AudienceEnum.Caregiver, Name: AudienceEnum.CaregiverDescription },
    { Id: AudienceEnum.FamilyMember, Name: AudienceEnum.FamilyMemberDescription },
];

export const LevelOfUnderstandings = [
    { Id: LevelOfUnderstandingEnum.Good, Name: LevelOfUnderstandingEnum.GoodDescription },
    { Id: LevelOfUnderstandingEnum.Fair, Name: LevelOfUnderstandingEnum.FairDescription },
    { Id: LevelOfUnderstandingEnum.Poor, Name: LevelOfUnderstandingEnum.PoorDescription },
    { Id: LevelOfUnderstandingEnum.Declined, Name: LevelOfUnderstandingEnum.DeclinedDescription },
];

export const LevelOfTeachbacks = [
    { Id: LevelOfTeachbackEnum.Good, Name: LevelOfTeachbackEnum.GoodDescription },
    { Id: LevelOfTeachbackEnum.Fair, Name: LevelOfTeachbackEnum.FairDescription },
    { Id: LevelOfTeachbackEnum.Poor, Name: LevelOfTeachbackEnum.PoorDescription },
    { Id: LevelOfTeachbackEnum.Declined, Name: LevelOfTeachbackEnum.DeclinedDescription },
];

export const EducationStatuses = [
    { Id: EducationStatusEnum.Accomplished, Name: EducationStatusEnum.AccomplishedDescription },
    { Id: EducationStatusEnum.InProgress, Name: EducationStatusEnum.InProgressDescription },
    { Id: EducationStatusEnum.Declined, Name: EducationStatusEnum.DeclinedDescription },
];

export const EducationTimeDurations = [
    { Id: EducationTimeDurationEnum.LessThan11Minutes, Name: EducationTimeDurationEnum.LessThan11MinutesDescription },
    { Id: EducationTimeDurationEnum.From11To19Minutes, Name: EducationTimeDurationEnum.From11To19MinutesDescription },
    { Id: EducationTimeDurationEnum.From20To30Minutes, Name: EducationTimeDurationEnum.From20To30MinutesDescription },
    { Id: EducationTimeDurationEnum.GreaterThan301Minutes, Name: EducationTimeDurationEnum.GreaterThan301MinutesDescription },
];

export const CarePlanStatus = [
    { Id: CarePlanEnum.Open, Name: CarePlanEnum.OpenDescription },
    { Id: CarePlanEnum.InProgress, Name: CarePlanEnum.InProgressDescription },
    { Id: CarePlanEnum.Achieved, Name: CarePlanEnum.AchievedDescription },
    { Id: CarePlanEnum.NotMet, Name: CarePlanEnum.NotMetDescription },
    { Id: CarePlanEnum.Deleted, Name: CarePlanEnum.DeletedDescription },
];

export const MemberStatuses = [
    { Id: MemberStatusEnum.Undetermined, Name: MemberStatusEnum.UndeterminedDescription, IsOpen: true },
    { Id: MemberStatusEnum.Active, Name: MemberStatusEnum.ActiveDescription, IsOpen: true },
    { Id: MemberStatusEnum.Suspended, Name: MemberStatusEnum.SuspendedDescription, IsOpen: true },
    { Id: MemberStatusEnum.DisenrolledFromHealthPlan, Name: MemberStatusEnum.DisenrolledFromHealthPlanDescription, IsOpen: false },
    { Id: MemberStatusEnum.RefusedToContinueServices, Name: MemberStatusEnum.RefusedToContinueServicesDescription, IsOpen: false },
    { Id: MemberStatusEnum.Unreachable, Name: MemberStatusEnum.UnreachableDescription, IsOpen: false },
    { Id: MemberStatusEnum.Deceased, Name: MemberStatusEnum.DeceasedDescription, IsOpen: false },
    { Id: MemberStatusEnum.DisenrolledFromCHP, Name: MemberStatusEnum.DisenrolledFromCHPDescription, IsOpen: false },
    { Id: MemberStatusEnum.RefusedToEnroll, Name: MemberStatusEnum.RefusedToEnrollDescription, IsOpen: false },
    { Id: MemberStatusEnum.Associate, Name: MemberStatusEnum.AssociateDescription, IsOpen: false },
    { Id: MemberStatusEnum.OffBoarding, Name: MemberStatusEnum.OffBoardingDescription, IsOpen: true },
    { Id: MemberStatusEnum.Scheduled, Name: MemberStatusEnum.ScheduledDescription, IsOpen: true },
    { Id: MemberStatusEnum.DisenrolledFromHRA, Name: MemberStatusEnum.DisenrolledFromHRADescription, IsOpen: false },
    { Id: MemberStatusEnum.HRAInReview, Name: MemberStatusEnum.HRAInReviewDescription, IsOpen: true },
    { Id: MemberStatusEnum.UncompensatedCare, Name: MemberStatusEnum.UncompensatedCareDescription, IsOpen: true },
    { Id: MemberStatusEnum.FUHUnd, Name: MemberStatusEnum.FUHUndDescription, IsOpen: true },
    { Id: MemberStatusEnum.FUHFUMInProgress, Name: MemberStatusEnum.FUHFUMInProgressDescription, IsOpen: true },
    { Id: MemberStatusEnum.FUHFUMClose, Name: MemberStatusEnum.FUHFUMCloseDescription, IsOpen: false },
    { Id: MemberStatusEnum.FUHClosedBillable, Name: MemberStatusEnum.FUHClosedBillableDescription, IsOpen: false },
    { Id: MemberStatusEnum.FUHClosedUnbillable, Name: MemberStatusEnum.FUHClosedUnbillableDescription, IsOpen: false },
];

export const EducationTypes = {
    AsthmaCOPD: { Id: '5b1f9c5504a2f823542f53c1', Name: 'Asthma/COPD' },
    CHF: { Id: '5b1f9c5504a2f823542f53c2', Name: 'CHF' },
    Diabetes: { Id: '5b1f9c5504a2f823542f53c3', Name: 'Diabetes' },
    Hypertension: { Id: '5b1f9c5504a2f823542f53c4', Name: 'Hypertension' },
    Other: { Id: '5b1f9c5504a2f823542f53c5', Name: 'Other' },
    CopingSkills: { Id: '5b1f9c5504a2f823542f5cc1', Name: 'Coping Skills' },
    HarmReductionSafetyPlan: { Id: '5b1f9c5504a2f823542f5cc2', Name: 'Harm Reduction-Safety Plan' },
    HealthyNutrition: { Id: '5b1f9c5504a2f823542f5cc3', Name: 'Healthy Nutrition' },
    SmokingCessation: { Id: '5b1f9c5504a2f823542f5cc4', Name: 'Smoking Cessation' },
    AdvanceCarePlanning: { Id: '5b1f9c5504a2f823542f5cc5', Name: 'Advance Care Planning-Life Planning' },
    InitialEducation: { Id: '5b1f9c5504a2f823542f5bc1', Name: 'Initial Education-Set Parameters' },
    InteractiveEducation: { Id: '5b1f9c5504a2f823542f5bc2', Name: 'Interactive Education Follow Up' },
};

export const ProviderRoleWidgets = {
    ENCOUNTERS_MISMATCH: 'EncountersMismatch',
    AGED_SERVICES: 'AgedServices',
    AGED_REQUESTS: 'AgedRequests',
    RECENT_MEDICAL_DOCUMENTS: 'RecentMedicalDocuments',
    MEMBERS_WITH_NO_CONSENT_FORM: 'MembersWithNoConsentForm',
    APPOINTMENTS_VS_ENCOUNTERS: 'AppointmentsVsEncounters',
    HIGHEST_COST_MEMBERS: 'HighestCostMembers',
    TELEMONITORING_OUT_OF_LIMITS_BY_TEAM: 'TelemonitoringOutOfLimitsByTeam',
    TELEMONITORING_OUT_OF_LIMITS_BY_USER: 'TelemonitoringOutOfLimitsByUser',
    APPOINTMENTS_NOT_YET_CONFIRMED: 'AppointmentsNotYetConfirmed',
    CANCELED_AND_NOT_RESCHEDULED: 'CanceledAndNotRescheduled',
    MEMBERS_NOT_SCHEDULED_WITH_CC_AND_NP: 'MembersNotScheduledWithCCandNP',
    ER_ADMISSIONS: 'ERAdmissions',
    TRANSITIONAL_CARE: 'TransitionalCare',
    PHQ9: 'PHQ9',
    ONBOARDING_PERFORMANCE: 'OnboardingPerformance',
    CALLING_PERFORMANCE: 'CallingPerformance',
    RESCHEDULING_OPPORTUNITIES: 'ReschedulingOpportunities',
    EDUCATION_SESSION_REMINDER: 'EducationSessionReminder',
};
