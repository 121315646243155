import { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DescriptionIcon from '@mui/icons-material/Description';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PhoneIcon from '@mui/icons-material/Phone';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

import { parentUrl } from '../../../utils/constants';
import Authorization from '../../../utils/Authorization';

const HamburgerMenu = () => {
    const authData = new Authorization();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };

    const menuItems = [
        { text: 'Upcoming Appointments', link: `${parentUrl}provider/upcomingappointments`, icon: <CalendarMonthIcon /> },
        { text: 'Dashboard', link: `${parentUrl}provider/metricsummary`, icon: <DashboardIcon /> },
        authData.isInPermission('View members') && { text: 'Members', link: `${parentUrl}provider/members-list`, icon: <GroupsIcon /> },
        authData.isInPermission('View the requests') && { text: 'Requests', link: `${parentUrl}provider/requests`, icon: <FileOpenIcon /> },
        authData.isInPermission('View onboarding') && { text: 'Onboarding', link: `${parentUrl}provider/onboarding-workflow`, icon: <PhoneIcon /> },
        authData.isInPermission('View my schedule') && { text: 'My Schedule', link: `${parentUrl}provider/myschedule`, icon: <CalendarMonthIcon /> },
        authData.isInPermission('View all appointment') && { text: 'Schedules', link: `${parentUrl}provider/schedules`, icon: <CalendarMonthIcon /> },
        authData.isInPermission('View Reports') && { text: 'Reports', link: `${parentUrl}provider/reports`, icon: <DescriptionIcon /> },
        authData.isInPermission('View HIE') && { text: 'HIE', link: `${parentUrl}provider/hie`, icon: <LocalHospitalIcon /> },
        authData.isInPermission('View Scheduling') && { text: 'Office Scheduling', link: `${parentUrl}provider/officescheduling`, icon: <CalendarMonthIcon /> },
        authData.isInPermission('View Claims Page') && { text: 'Claims', link: `${parentUrl}provider/claims-management`, icon: <MedicalInformationIcon /> },
    ].filter(Boolean);

    return (
        <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} sx={{ borderRadius: 0, paddingLeft: '20px' }}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
                <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} style={{ paddingTop: '60px' }}>
                    {menuItems.map((item, index) => (
                        <ListItem button key={index} component="a" href={item.link}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};

export default HamburgerMenu;
