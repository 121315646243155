import { useState } from 'react';
import { Avatar, Box, Button, Chip, Divider, FormControl, FormGroup, Grid, Input, InputLabel, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterStatusEnum } from '../../../Enum/EncounterStatusEnum';
import { RootState } from '../../../reducers';
import moment from 'moment-timezone';
import { getTop, setShowProcedureSignOffModal, updateEncounters, setClaimError } from '../../../store/procedureSignOffEncounter.slice';
import { largeModalStyle } from '../../../utils/constants';
import ProcedureSignOffEncounterEdit from './ProcedureSignOffEncounterEdit';

const ProcedureSignOffEncounter = (props) => {
    const dispatch = useDispatch();
    const { dateOfService, draftEncounterList, showProcedureSignOffModal } = useSelector((state: RootState) => state.procedureSignOffEncounter);
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const [showDxError, setShowDxError] = useState(false);

    const validateCpt = () => {
        if (draftEncounterList?.Encounters.some((e) => !Boolean(e.CptCodeId))) {
            dispatch(setClaimError({ Show: true, Message: 'Please add a primary CPT code to all encounters before submitting.' }));
            return false;
        }

        if (draftEncounterList?.Encounters.some((e) => !Boolean(e.Units))) {
            dispatch(setClaimError({ Show: true, Message: "Please verify you've selected Units." }));
            return false;
        }

        return true;
    };

    const handleOnSave = (isReview = false) => {
        if (!validateCpt()) {
            return;
        }

        let isDiagnosisMissed = false;
        draftEncounterList.Encounters.forEach((encounter) => {
            encounter.DiagnosisList.forEach((diagnosis) => {
                if (Boolean(diagnosis.DiagnosisFirstList?.length) && !Boolean(diagnosis.FirstDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisSecondList?.length) && !Boolean(diagnosis.SecondDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisThirdList?.length) && !Boolean(diagnosis.ThirdDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisFourthList?.length) && !Boolean(diagnosis.FourthDiagnosis)) {
                    isDiagnosisMissed = true;
                }
            });
        });

        if (isDiagnosisMissed) {
            dispatch(setClaimError({ Show: true, Message: 'Please select a diagnosis' }));
            setShowDxError(true);
            return;
        }

        if (isReview) {
            dispatch(updateEncounters(draftEncounterList, EncounterStatusEnum.ForReview, null));
        } else {
            dispatch(updateEncounters(draftEncounterList, EncounterStatusEnum.ToSubmit, null));
        }
    };

    const renderBody = () => {
        return (
            <>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs>
                            <Paper elevation={3} style={{ padding: '8px', textAlign: 'left' }}>
                                <Divider style={{ marginBottom: '16px' }}>
                                    <Chip label="Member" />
                                </Divider>
                                <Stack direction="row" spacing={2}>
                                    <Stack spacing={2}>
                                        <Avatar
                                            alt={selectedMember?.FirstName + ' ' + selectedMember?.LastName}
                                            src={selectedMember?.ProfileImageUrl}
                                            sx={{ width: 144, height: 144 }}
                                            className="profile-image"
                                        />
                                    </Stack>
                                    <Stack style={{ width: '100%' }} spacing={2}>
                                        <FormGroup>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    aria-readonly="true"
                                                    fullWidth
                                                    label="First Name"
                                                    variant="outlined"
                                                    value={selectedMember?.FirstName}
                                                />
                                                <TextField
                                                    aria-readonly="true"
                                                    fullWidth
                                                    label="Middle Name"
                                                    variant="outlined"
                                                    value={selectedMember?.MiddleName ? selectedMember?.MiddleName : ''}
                                                />
                                                <TextField
                                                    aria-readonly="true"
                                                    fullWidth
                                                    label="Last Name"
                                                    variant="outlined"
                                                    value={selectedMember?.LastName}
                                                />
                                            </Stack>
                                        </FormGroup>
                                        <FormGroup>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    aria-readonly="true"
                                                    fullWidth
                                                    label="AHCCCS ID"
                                                    variant="outlined"
                                                    value={selectedMember?.Mem_AHCCCS_ID}
                                                />
                                                <TextField aria-readonly="true" fullWidth label="DOB" variant="outlined" value={selectedMember?.DOB} />
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                                    <Input
                                                        disabled={true}
                                                        value={
                                                            selectedMember?.SSN ? 'XXX-XX-' + selectedMember?.SSN.substring(selectedMember?.SSN.length - 4) : ''
                                                        }
                                                    />
                                                </FormControl>
                                            </Stack>
                                        </FormGroup>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={3} style={{ padding: '8px', marginLeft: '8px', textAlign: 'left', minHeight: '208px' }}>
                                <Divider style={{ marginBottom: '16px' }}>
                                    <Chip label="Encounter Information" />
                                </Divider>
                                <Stack direction="row" spacing={2}>
                                    <Stack style={{ width: '100%' }} spacing={2}>
                                        <FormGroup>
                                            <Stack spacing={2}>
                                                <TextField
                                                    aria-readonly="true"
                                                    fullWidth
                                                    label="Date of Service"
                                                    variant="outlined"
                                                    value={moment(dateOfService).format('MM/DD/yyyy hh:mm A')}
                                                />
                                            </Stack>
                                        </FormGroup>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                    <ProcedureSignOffEncounterEdit dxError={showDxError} encounterStatus={props.encounterStatus} searchClaims={props.searchClaims} />
                    <Box>
                        <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                            <Button
                                className="button-120"
                                variant="contained"
                                onClick={() => {
                                    handleOnSave(true);
                                }}
                            >
                                Save for Review
                            </Button>
                            <Button
                                className="button-120"
                                variant="contained"
                                onClick={() => {
                                    handleOnSave(false);
                                }}
                            >
                                Submit Claim
                            </Button>
                            <Button
                                className="button-120"
                                variant="outlined"
                                onClick={() => {
                                    dispatch(setShowProcedureSignOffModal(false));
                                }}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </>
        );
    };

    return (
        <Modal
            open={showProcedureSignOffModal}
            style={{ overflow: 'scroll' }}
            onClose={() => {
                dispatch(setShowProcedureSignOffModal(false));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={largeModalStyle} style={{ top: getTop(draftEncounterList) }}>
                <Stack spacing={2}>
                    <Typography variant="h5">Edit Procedure Sign Off</Typography>
                    <Divider />
                    {renderBody()}
                </Stack>
            </Box>
        </Modal>
    );
};

export default ProcedureSignOffEncounter;
