/* eslint-disable react-hooks/exhaustive-deps */
import { RichTextEditor } from '@mantine/rte';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IMemberSelectOption from '../../../Models/Member/MemberSelectOption.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import { RootState } from '../../../reducers';
import { setAnswers, setIsNewRequestModalOpen, submitNewRequest } from '../../../store/newRequest.slice';
import { REQUEST_API } from '../../../store/requests.slice';
import { ProviderId as sessionProviderId } from '../../../utils/constants';

import { IRequest, IRequestQuestion, IRequestQuestionAnswer, IRequestQuestionType } from '../../Requests/interfaces';
import RequestsQuestionCard from '../../Requests/RequestsQuestions/RequestsQuestionCard';

import { activityOptions, addActivityNoteHandler, CallListTabs, createNote, setDemographicsRequestIsOpen } from '../../../store/onboardingWorkflow.slice';
import { Provider } from '../../../Models/Provider/Provider.model';
import Authorization from '../../../utils/Authorization';

export interface INewRequestModalProps {
    lockedMemberOption?: IMemberSelectOption;
    providerOption?: IProviderSelectOption;
}

type mappedRequestQuestionTypeOption = {
    value: string;
    label: string;
    subRequestTypes?: IRequestQuestionType[];
    isNotEncounterable?: boolean;
    Zcode: string;
};

const DemographicsRequest: React.FC<INewRequestModalProps> = ({ lockedMemberOption }) => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const { callItem, confirmationCallItem, demographicsRequestIsOpen, selectedCallListTab } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const providerRef = React.useRef<HTMLDivElement>(null);
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const { answers } = useSelector((state: RootState) => state.newRequest);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isFetchingQuestions, setIsFetchingQuestions] = useState<boolean>(false);
    const [requestQuestionList, setRequestQuestionList] = useState<IRequestQuestion[]>([]);
    const [requestDescription, setRequestDescription] = useState<string>('');
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [requestTypeOptions, setRequestTypeOptions] = useState<mappedRequestQuestionTypeOption[]>([]);
    const [requestTypeOption, setRequestTypeOption] = useState<mappedRequestQuestionTypeOption>();
    const [fullProviderOptionsList, setFullProviderOptionsList] = useState<IProviderSelectOption[]>([]);

    const [selectedMemberOption, setSelectedMemberOption] = useState<IMemberSelectOption | null>(
        lockedMemberOption?.MemberId?.length ? lockedMemberOption : null
    );
    const [selectedProviderOption, setSelectedProviderOption] = useState<IProviderSelectOption | null>(null);

    const [priorityOption, setPriorityOption] = useState<number>(1);
    const [providerError, setProviderError] = useState<boolean>(false);
    const [memberError, setMemberError] = useState<boolean>(false);

    useEffect(() => {
        if (fullProviderOptionsList.length === 0) {
            const providers = activeProviders.filter((provider) => provider.Role.RoleName === 'Support Analyst') as Provider[];
            const options = (providers as Provider[])
                .map((row) => {
                    return {
                        ProviderId: row.Id,
                        ProviderName: `${row.LastName}, ${row.FirstName}`,
                        FirstName: row.FirstName,
                        LastName: row.LastName,
                    };
                })
                .sort((a, b) => {
                    let lastNameComparison = a.LastName.localeCompare(b.LastName);
                    if (lastNameComparison !== 0) {
                        return lastNameComparison;
                    }

                    return a.FirstName.localeCompare(b.FirstName);
                });
            setFullProviderOptionsList(options);
            setProviderOptions(options);
        }
    }, [activeProviders]);

    const demographicsResearchRequestType = useMemo(() => {
        return requestTypeOptions.find((el) => el.Zcode === 'Z71.0');
    }, [requestTypeOptions]);

    const questionCards = useMemo(() => {
        if (requestQuestionList?.length === 0) {
            return (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography variant="h6">There aren't any questions for this request type.</Typography>
                    </Grid>
                </Grid>
            );
        }
        if (requestQuestionList && answers.length === requestQuestionList.length) {
            return React.Children.toArray(
                requestQuestionList.map((question, index) => {
                    const setAnswer = (answer: Partial<IRequestQuestionAnswer>) => {
                        const newAnswers = [...answers];
                        newAnswers[index] = { ...newAnswers[index], ...answer };
                        dispatch(setAnswers(newAnswers));
                    };
                    return (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <RequestsQuestionCard
                                debounced={false}
                                QuestionId={question.Id}
                                {...question}
                                index={index}
                                setAnswer={setAnswer}
                                isNewQuestion={true}
                            />
                        </Grid>
                    );
                })
            );
        }
    }, [requestQuestionList, answers]);

    const fetchRequestTypeOptions = async () => {
        const deprecatedTypes = ['Others'];
        const response = await axios.get(`${REQUEST_API}/GetRequestTypesList`);
        if (response?.data) {
            const requestTypes: mappedRequestQuestionTypeOption[] = (
                response.data.filter((el) => el.RequestTypeLabel === 'Demographic Research') as IRequestQuestionType[]
            )
                .map(({ Id, RequestTypeLabel, ZCode: Zcode, SubRequestTypes, IsNotEncounterable }) => {
                    return {
                        value: Id,
                        subRequestTypes: SubRequestTypes,
                        label: RequestTypeLabel,
                        isNotEncounterable: IsNotEncounterable,
                        Zcode: Zcode,
                    };
                })
                .filter(({ label }) => !deprecatedTypes.includes(label))
                .sort((a, b) => a.label.localeCompare(b.label));
            setRequestTypeOptions(requestTypes);
            setRequestTypeOption(requestTypes[0]);
        }
    };

    const fetchQuestionList = async (typeId: string) => {
        setIsFetchingQuestions(true);
        setRequestQuestionList([]);
        const request = axios.get(`${REQUEST_API}/GetRequestTypeQuestionList/${demographicsResearchRequestType.value}`);
        request
            .then((response) => {
                setRequestQuestionList(response.data);
            })
            .finally(() => {
                setIsFetchingQuestions(false);
            });
    };

    useEffect(() => {
        if (demographicsResearchRequestType?.value !== undefined) {
            fetchQuestionList(demographicsResearchRequestType.value);
        }
    }, [demographicsResearchRequestType]);

    useEffect(() => {
        if (lockedMemberOption?.MemberId?.length > 0) {
            setSelectedMemberOption(lockedMemberOption);
        }
    }, [lockedMemberOption?.MemberId?.length]);

    useEffect(() => {
        fetchRequestTypeOptions();
    }, []);

    const toggleRequestModal = () => {
        setSelectedMemberOption(lockedMemberOption?.MemberId?.length ? lockedMemberOption : null);
        setPriorityOption(1);
        setRequestDescription('');
        setProviderError(false);
        setMemberError(false);
        setSelectedProviderOption(null);
        dispatch(setDemographicsRequestIsOpen(!demographicsRequestIsOpen));
    };

    const handleSubmitClick = async () => {
        if (selectedProviderOption?.ProviderId?.length && lockedMemberOption?.MemberId.length) {
            const { MemberId: memberId } = lockedMemberOption;
            const { ProviderId: providerId } = selectedProviderOption;
            const newRequest: Partial<IRequest> = {
                Message: requestDescription,
                RequestFor: {
                    Id: memberId,
                    Name: '',
                },
                RequestTo: {
                    Id: providerId,
                    Name: '',
                    Role: '',
                },
                RequestFrom: {
                    Id: sessionProviderId,
                    Name: '',
                    Role: '',
                },
                Priority: priorityOption,
                ForwardBy: [],
                Type: 1,
                RequestQuestionAnswers: answers as IRequestQuestionAnswer[],
                Status: 1,
                Zcode: requestTypeOption.Zcode ?? '',
                CreatedOn: new Date(),
                IsNotEncounterable: requestTypeOption.isNotEncounterable ?? false,
                RequestTypeObjectId: requestTypeOption?.value,
                SubRequestTypeLabel: '',
            };
            const newNote = createNote(
                item.MemberId,
                activityOptions.DEMOGRAPHIC_REQUEST,
                `A Demographic Research Request was sent to "${selectedProviderOption.ProviderName}".`,
                auth
            );

            dispatch(addActivityNoteHandler(item, newNote, isConfirmCall));
            dispatch(submitNewRequest(newRequest));
            toggleRequestModal();
        } else {
            setProviderError(!selectedProviderOption?.ProviderId?.length);
            setMemberError(!selectedMemberOption?.MemberId?.length);
        }
    };

    const newRequestInfoPage = (
        <>
            <DialogContent>
                <Grid container justifyContent="center" spacing={3} style={{ paddingTop: 10 }}>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <Autocomplete
                            ref={providerRef}
                            fullWidth
                            disableClearable
                            id="request-provider-selection"
                            options={providerOptions}
                            isOptionEqualToValue={(option, value: any) => {
                                return option.ProviderId === value.ProviderId;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Assigned Provider"
                                    required
                                    error={providerError}
                                    helperText={providerError && 'A Provider is Required'}
                                />
                            )}
                            value={selectedProviderOption as any}
                            onChange={(event: any, newValue: any) => {
                                if (newValue?.value) {
                                    setSelectedProviderOption(newValue.value);
                                } else {
                                    setSelectedProviderOption(newValue);
                                }
                                if (providerError) {
                                    setProviderError(false);
                                }
                            }}
                            inputValue={providerInputValue}
                            onInputChange={(event, value) => {
                                setProviderInputValue(value);
                            }}
                            getOptionLabel={(option: any) => option.ProviderName}
                        />
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <TextField fullWidth variant="outlined" label="Assigned Member" value={lockedMemberOption.MemberName} disabled />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                        <TextField
                            fullWidth
                            select
                            label="Priority"
                            variant="outlined"
                            value={priorityOption}
                            onChange={(event) => {
                                setPriorityOption(Number(event.target?.value));
                            }}
                        >
                            <MenuItem value={1}>Low</MenuItem>
                            <MenuItem value={2}>Medium</MenuItem>
                            <MenuItem value={3}>High</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ paddingBottom: 10 }} variant="h5">
                            Message / Description
                        </Typography>
                        <RichTextEditor value={requestDescription} onChange={setRequestDescription} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h5">Request Type and Questionnaire</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {requestTypeOptions && (
                            <TextField
                                sx={{
                                    maxWidth: 300,
                                    marginBottom: 2,
                                }}
                                variant="outlined"
                                label="Request Type"
                                disabled
                                value={requestTypeOption?.label ?? ''}
                            />
                        )}
                    </Grid>

                    <Grid container spacing={2} item>
                        {!isFetchingQuestions ? (
                            questionCards
                        ) : (
                            <Grid item container justifyContent={'center'} alignItems="center">
                                <Grid item>
                                    <CircularProgress size={80} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {providerError && (
                    <Typography variant="caption" fontSize={12} color="red" style={{ paddingRight: 20 }}>
                        <sup>*</sup>Assigned Provider is required
                    </Typography>
                )}
                {memberError && (
                    <Typography variant="caption" fontSize={12} color="red" style={{ paddingRight: 20 }}>
                        <sup>*</sup>Assigned Member is required
                    </Typography>
                )}
                <Button variant="contained" onClick={handleSubmitClick} color={memberError || providerError ? 'error' : undefined}>
                    Submit
                </Button>
                <Button variant="contained" onClick={toggleRequestModal}>
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={demographicsRequestIsOpen}
            fullWidth
            onClose={() => dispatch(setIsNewRequestModalOpen(false))}
            maxWidth={'lg'}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Demographic Research Request</DialogTitle>
            {newRequestInfoPage}
        </Dialog>
    );
};

export default DemographicsRequest;
