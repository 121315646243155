import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { fetchMemberDemoGraphicInfo } from './memberDetail.slice';
import { convertEncountersToEncounterList, setDateOfService, setDraftEncounterList } from './procedureSignOffEncounter.slice';
import { convertEncountersToList } from './encounter.slice';
import { fetchProvider, setIsLoading } from './shared.slice';

const claimResubmission = createSlice({
    name: 'claimResubmission',
    initialState: {
        claim: null,
        isResubmit: false,
        claimNumber: '',
        dateOfService: '',
        showEditResubmitModal: false,
    },
    reducers: {
        _setClaim(state, action) {
            state.claim = action.payload;
        },
        _setClaimNumber(state, action) {
            state.claimNumber = action.payload;
        },
        _setDateOfService(state, action) {
            state.dateOfService = action.payload;
        },
        _setIsResubmit(state, action) {
            state.isResubmit = action.payload;
        },
        _setShowEditResubmitModal(state, action) {
            state.showEditResubmitModal = action.payload;
        },
    },
});

const { _setClaim, _setClaimNumber, _setDateOfService, _setIsResubmit, _setShowEditResubmitModal } = claimResubmission.actions;

export const fetchClaimResubmitEditData =
    (claimNumber: string, memberId: string, dateOfService: string, callback: Function): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        dispatch(_setClaimNumber(claimNumber));
        dispatch(_setDateOfService(dateOfService));

        const response = await axios.get(`${API_URL}/encounter/getencountersfromclaim?claimNumber=${claimNumber}`);
        if (response.data.length > 0) {
            dispatch(fetchProvider(response.data[0].CreatedBy.Id));
            dispatch(setDateOfService(response.data[0].DateOfService));
            let encounterList = await convertEncountersToEncounterList(response.data);
            encounterList = convertEncountersToList(encounterList);
            dispatch(setDraftEncounterList(encounterList));
        }
        dispatch(fetchMemberDemoGraphicInfo(memberId, callback));

        const claimResponse = await axios.get(`${API_URL}/encounter/getclaimbyclaimnumber?claimNumber=${claimNumber}`);
        dispatch(_setClaim(claimResponse.data[0]));
        dispatch(setIsLoading(false));
    };

export const setClaim =
    (claim: any): AppThunk =>
    (dispatch) => {
        dispatch(_setClaim(claim));
    };

export const setShowEditResubmitModal =
    (value: boolean, isResubmit: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsResubmit(isResubmit));
        dispatch(_setShowEditResubmitModal(value));
    };

export default claimResubmission.reducer;
