import { PreferredContactEnum } from '../../Enum/PreferredContactEnum';
import { ProviderStatusEnum } from '../../Enum/ProviderStatusEnum';
import { ProviderTypeEnum } from '../../Enum/ProviderTypeEnum';
import BaseEntity from '../BaseEntity.model';
import { ProviderRole } from '../DocumentEngagements/EngagementDraft.model';

export default interface IProvider extends BaseEntity {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    NPINumber: string;
    Address: string;
    City: string;
    State: string;
    ZipCode: string;
}

export class ProviderLicensure {
    Id: string;
    Name: string;

    constructor() {
        this.Id = '';
        this.Name = '';
    }
}

export class Provider implements IProvider {
    Id: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    NPINumber: string;
    Address: string;
    City: string;
    State: string;
    ZipCode: string;
    HomePhone: string;
    MobilePhone: string;
    OfficePhone: string;
    PreferredContact: PreferredContactEnum;
    Email: string;
    FaxNumber: string;
    Role: ProviderRole;
    ProviderType: ProviderTypeEnum;
    Status: ProviderStatusEnum;
    UserId: string;
    VeradigmProviderAdmin: boolean;
    VeradigmUserGuid: string;
    DEAnumber: string;
    DEAexpiration: string;
    StateLicenseNumber: string;
    StateLicenseExpiration: string;
    StateIssuingLicense: string;
    MeansOfEncounter: string;
    GroupNPINumber: string;
    Licensure: ProviderLicensure;

    constructor() {
        this.Id = '';
        this.FirstName = '';
        this.MiddleName = '';
        this.LastName = '';
        this.FullName = '';
        this.NPINumber = '';
        this.Address = '';
        this.City = '';
        this.State = '';
        this.ZipCode = '';
    }
}
