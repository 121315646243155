import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { roleEnum } from '../../utils/assessments';
import { states } from '../../utils/constants';
import { RootState } from '../../reducers';
import {
    ERROR,
    SUCCESS,
    editProvider,
    handleClose,
    hasProviderPrescribingRole,
    insertProvider,
    setFormIsValid,
    setShowVeradigmModal,
} from '../../store/adminProvider.slice';
import MessageDisplay from '../Shared/MessageDisplay';
import { setShowMessage } from '../../store/shared.slice';
import RegisterVeradigmModal from './RegisterVeradigmModal';
import { licensures } from '../../utils/constants';
import { dateFormatter } from '../../utils/timeFormat';
import { formValidation, isNPorMD } from './AdminProviders';
import { Provider } from '../../Models/Provider/Provider.model';

const phoneFormat = (phoneNumber: string) => {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return cleaned;
};

const formatDates = (object) => {
    let copy = { ...object };

    if (object.DEAexpiration) {
        copy.DEAexpiration = dateFormatter(new Date(object.DEAexpiration));
    }
    if (object.StateLicenseExpiration) {
        copy.StateLicenseExpiration = dateFormatter(new Date(object.StateLicenseExpiration));
    }
    return copy;
};

const ProviderDialog = ({ providerData, setProviderData }) => {
    const { formIsValid, isOpen, preferredContactOptions, providerRoleOptions, showVeradigmModal, title } = useSelector(
        (state: RootState) => state.adminProvider
    );
    const errorStyles = {
        [`& .MuiOutlinedInput-notchedOutline, & .Mui-focused`]: {
            borderColor: '#f00!important',
        },
        '& .MuiInputLabel-root': {
            color: '#f00',
        },
        '& .MuiFormHelperText-root': {
            color: '#f00',
        },
    };

    const dispatch = useDispatch();
    const validForm = formValidation(providerData);

    const inputOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'Role') {
            dispatch(
                setProviderData({
                    ...providerData,
                    Role: { RoleName: value.Name, Id: value.Id },
                })
            );
        } else {
            dispatch(setProviderData({ ...providerData, [name]: value }));
        }
        formValidation(providerData);
    };

    const submitHandler = () => {
        if (providerData.Id.length > 0) {
            dispatch(editProvider(validForm, formatDates(providerData)));
        } else {
            dispatch(insertProvider(validForm, formatDates(providerData)));
        }
    };

    const checkRequiredVeradigmProviderFields = (providerData: Provider) => {
        const requiredFields = ['FirstName', 'LastName', 'Email', 'NPINumber', 'StateIssuingLicense', 'StateLicenseNumber', 'StateLicenseExpiration'];
        let incompleteFields = [];
        requiredFields.forEach((field) => {
            if (!providerData[field]) {
                incompleteFields.push(field);
            }
        });
        return incompleteFields;
    };

    const registerVeradigmHandler = () => {
        if (providerData.Id === '' || providerData.Id === null) {
            dispatch(setShowMessage(true, `Only existing Providers can be registered with Veradigm.`, 'error'));
            return;
        }
        const incompleteFields = checkRequiredVeradigmProviderFields(providerData);
        if (incompleteFields.length > 0) {
            const message = `Missing required fields: ${incompleteFields.join(', ').replace('NPINumber', 'Personal NPI Number')}`;
            dispatch(setShowMessage(true, message, 'error'));
            dispatch(setFormIsValid(false));
            return;
        }

        dispatch(setShowVeradigmModal(true));
    };

    const requiresLicensure = (roleName: string) => {
        return roleName === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST || roleName === roleEnum.NURSE_PRACTITIONER;
    };

    return (
        <>
            <MessageDisplay />
            <RegisterVeradigmModal open={showVeradigmModal} onClose={() => dispatch(setShowVeradigmModal(false))} />
            <Dialog open={isOpen} onClose={() => dispatch(handleClose())} fullWidth disableEnforceFocus maxWidth="xl">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                            <Grid item xs={12}>
                                <DialogContentText fontWeight={'bold'}>Personal Info</DialogContentText>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    autoFocus
                                    required
                                    value={providerData.FirstName}
                                    id="first-name"
                                    label="First Name"
                                    name="FirstName"
                                    error={!formIsValid && providerData.FirstName.length === 0}
                                    helperText={'Required'}
                                    fullWidth
                                    variant="outlined"
                                    onChange={inputOnChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="middle-name"
                                    value={providerData.MiddleName || ''}
                                    label="Middle Name"
                                    name="MiddleName"
                                    fullWidth
                                    variant="outlined"
                                    onChange={inputOnChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    value={providerData.LastName}
                                    id="last-name"
                                    label="Last Name"
                                    name="LastName"
                                    error={!formIsValid && providerData.LastName.length === 0}
                                    helperText={'Required'}
                                    fullWidth
                                    variant="outlined"
                                    onChange={inputOnChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel error={!formIsValid && providerData.Role === undefined} required id="demo-simple-select-label">
                                        Role
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="provider-roles"
                                        defaultValue={''}
                                        value={providerData.Role?.Id || ''}
                                        error={!formIsValid && providerData.Role === undefined}
                                        margin="dense"
                                        label="Role"
                                        name="Role"
                                        onChange={(e) => {
                                            const role = providerRoleOptions.find((el) => el.Id === e.target.value);
                                            if (!requiresLicensure(role.RoleName)) {
                                                dispatch(
                                                    setProviderData({
                                                        ...providerData,
                                                        Role: { Id: role.Id, RoleName: role.Name },
                                                        Licensure: null,
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    setProviderData({
                                                        ...providerData,
                                                        Role: { Id: role.Id, RoleName: role.Name },
                                                    })
                                                );
                                            }
                                        }}
                                    >
                                        <MenuItem disabled value={''}>
                                            Select a Role
                                        </MenuItem>
                                        {providerRoleOptions.map((item) => {
                                            return (
                                                <MenuItem key={item.Id} value={item.Id}>
                                                    {item.Name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText error={!formIsValid && providerData.Role === undefined}>Required</FormHelperText>
                                </FormControl>
                            </Grid>

                            {requiresLicensure(providerData.Role?.RoleName) ? (
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Licensure</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="licensures"
                                            value={providerData.Licensure?.Id || '-1'}
                                            margin="dense"
                                            label="Licensure"
                                            name="Licensure"
                                            onChange={(e) => {
                                                const licensure = licensures.find((el) => el.Id === e.target.value) || null;
                                                dispatch(
                                                    setProviderData({
                                                        ...providerData,
                                                        Licensure: licensure,
                                                    })
                                                );
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value={'-1'}>
                                                None
                                            </MenuItem>
                                            {licensures
                                                .sort((a, b) => a.Name.localeCompare(b.Name))
                                                .map((item) => {
                                                    return (
                                                        <MenuItem key={item.Id} value={item.Id}>
                                                            {item.Name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            <Grid item xs={12}>
                                <DialogContentText fontWeight={'bold'}>Address Info</DialogContentText>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    value={providerData.MainAddress?.Address1 || ''}
                                    margin="dense"
                                    id="address"
                                    label="Address"
                                    fullWidth
                                    variant="outlined"
                                    name="Address1"
                                    onChange={(e) => {
                                        let tmp = { ...providerData.MainAddress };
                                        tmp.Address1 = e.target.value;
                                        dispatch(
                                            setProviderData({
                                                ...providerData,
                                                MainAddress: tmp,
                                            })
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    value={providerData.MainAddress?.City || ''}
                                    margin="dense"
                                    id="city"
                                    label="City"
                                    fullWidth
                                    variant="outlined"
                                    name="City"
                                    onChange={(e) => {
                                        let tmp = { ...providerData.MainAddress };
                                        tmp.City = e.target.value;
                                        dispatch(
                                            setProviderData({
                                                ...providerData,
                                                MainAddress: tmp,
                                            })
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth sx={{ margin: '8px 0px 4px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="provider-state"
                                        options={states}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        freeSolo
                                        value={providerData.MainAddress?.State || ''}
                                        onChange={(event, value) => {
                                            let tmp = { ...providerData.MainAddress };
                                            tmp.State = value;
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    MainAddress: tmp,
                                                })
                                            );
                                        }}
                                        inputValue={providerData.MainAddress?.State}
                                        onInputChange={(event, value) => {
                                            let tmp = { ...providerData.MainAddress };
                                            tmp.State = value;
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    MainAddress: tmp,
                                                })
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} label="State" />}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    value={providerData.MainAddress?.ZipCode || ''}
                                    margin="dense"
                                    id="zip-code"
                                    label="Zip Code"
                                    fullWidth
                                    variant="outlined"
                                    name="ZipCode"
                                    onChange={(e) => {
                                        let tmp = { ...providerData.MainAddress };
                                        tmp.ZipCode = e.target.value;
                                        dispatch(
                                            setProviderData({
                                                ...providerData,
                                                MainAddress: tmp,
                                            })
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid marginBottom={0} item xs={12}>
                                <DialogContentText fontWeight={'bold'}>Contact Info</DialogContentText>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={phoneFormat(providerData.MobilePhone) || ''}
                                    id="mobile-phone"
                                    label="Mobile Phone"
                                    placeholder="Mobile Phone"
                                    fullWidth
                                    variant="outlined"
                                    name="MobilePhone"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={phoneFormat(providerData.HomePhone) || ''}
                                    id="home-phone"
                                    label="Home Phone"
                                    fullWidth
                                    variant="outlined"
                                    name="HomePhone"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={phoneFormat(providerData.OfficePhone) || ''}
                                    id="office-phone"
                                    label="Office Phone"
                                    fullWidth
                                    variant="outlined"
                                    name="OfficePhone"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={phoneFormat(providerData.FaxNumber) || ''}
                                    id="fax-number"
                                    label="Fax Number"
                                    fullWidth
                                    variant="outlined"
                                    name="FaxNumber"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth sx={{ margin: '8px 0px 4px' }}>
                                    <InputLabel error={!formIsValid && providerData.PreferredContact === undefined} required id="demo-simple-select-label">
                                        Preferred Contact
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        required
                                        value={providerData.PreferredContact || ''}
                                        error={!formIsValid && providerData.PreferredContact === undefined}
                                        margin="dense"
                                        label="Preferred Contact"
                                        name="PreferredContact"
                                        onChange={(e) =>
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    PreferredContact: e.target.value,
                                                })
                                            )
                                        }
                                    >
                                        {preferredContactOptions.map((item) => {
                                            return (
                                                <MenuItem key={parseInt(item.value)} value={parseInt(item.value)}>
                                                    {item.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText error={!formIsValid && providerData.PreferredContact === undefined}>Required</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    required
                                    value={providerData.Email || ''}
                                    margin="dense"
                                    id="email"
                                    error={(!formIsValid && !providerData.Email?.includes('@')) || (!formIsValid && providerData.Email?.length === 0)}
                                    helperText={!providerData.Email?.includes('@') && providerData.Email?.length > 0 ? 'Invalid Email' : 'Required'}
                                    label="Email"
                                    fullWidth
                                    variant="outlined"
                                    name="Email"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DialogContentText fontWeight={'bold'}>Additional Info</DialogContentText>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth sx={{ margin: '8px 0px 4px' }}>
                                    <InputLabel error={!formIsValid && providerData.ProviderType === undefined} required id="demo-simple-select-label">
                                        Provider Type
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={providerData.ProviderType || ''}
                                        error={!formIsValid && providerData.ProviderType === undefined}
                                        margin="dense"
                                        label="Provider Type"
                                        name="ProviderType"
                                        onChange={(e) =>
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    ProviderType: parseInt(e.target.value),
                                                })
                                            )
                                        }
                                    >
                                        <MenuItem disabled value={''}>
                                            Select Provider Type
                                        </MenuItem>
                                        <MenuItem value={1}>Internal</MenuItem>
                                        <MenuItem value={2}>External</MenuItem>
                                    </Select>
                                    <FormHelperText error={!formIsValid && providerData.ProviderType === undefined}>Required</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="DEA Expiration Date"
                                        value={providerData.DEAexpiration || null}
                                        onChange={(newValue) =>
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    DEAexpiration: newValue,
                                                })
                                            )
                                        }
                                        renderInput={(params) => <TextField margin="dense" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={providerData.DEAnumber || ''}
                                    id="dea-number"
                                    label="DEA Number"
                                    error={
                                        providerData.DEAnumber !== null &&
                                        !/[a-zA-Z]{2}\d{7}/.test(providerData.DEAnumber) &&
                                        providerData.DEAnumber?.length > 0
                                    }
                                    helperText={
                                        providerData.DEAnumber !== null &&
                                        (!/[a-zA-Z]{2}\d{7}/.test(providerData.DEAnumber) && providerData.DEAnumber?.length > 0 ? 'Invalid DEA Number' : '')
                                    }
                                    fullWidth
                                    variant="outlined"
                                    name="DEAnumber"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={3} alignSelf={'center'}>
                                {dispatch(hasProviderPrescribingRole(providerData)) ? (
                                    <Stack spacing={1}>
                                        <Button variant="contained" onClick={registerVeradigmHandler}>
                                            Veradigm Registration
                                        </Button>
                                        <Typography variant="subtitle2" textAlign={'center'} color={providerData.VeradigmUserGuid ? SUCCESS : ERROR}>
                                            {providerData.VeradigmUserGuid
                                                ? 'Provider is registered with Veradigm'
                                                : 'Provider is not registered with Veradigm'}
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    required={isNPorMD(providerData)}
                                    value={providerData.StateLicenseNumber || ''}
                                    id="state-license-number"
                                    label="State License Number"
                                    error={
                                        isNPorMD(providerData) &&
                                        !formIsValid &&
                                        (!providerData.StateLicenseNumber || providerData.StateLicenseNumber === undefined)
                                    }
                                    helperText={isNPorMD(providerData) && 'Required'}
                                    fullWidth
                                    variant="outlined"
                                    name="StateLicenseNumber"
                                    onChange={inputOnChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormControl
                                        sx={
                                            !formIsValid &&
                                            isNPorMD(providerData) &&
                                            (new Date(providerData.StateLicenseExpiration).toString() === 'Invalid Date' ||
                                                !providerData.StateLicenseExpiration)
                                                ? errorStyles
                                                : {}
                                        }
                                    >
                                        <DatePicker
                                            label="State License Expiration"
                                            value={providerData.StateLicenseExpiration || ''}
                                            onChange={(newValue) =>
                                                dispatch(
                                                    setProviderData({
                                                        ...providerData,
                                                        StateLicenseExpiration: newValue,
                                                    })
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={
                                                        (new Date(providerData.StateLicenseExpiration).toString() === 'Invalid Date' ||
                                                            !providerData.StateLicenseExpiration) &&
                                                        isNPorMD(providerData) &&
                                                        !formIsValid
                                                    }
                                                    required={isNPorMD(providerData)}
                                                    helperText={isNPorMD(providerData) && 'Required'}
                                                    margin="dense"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth sx={{ margin: '8px 0px 4px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="state-issuing-license"
                                        options={states}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        freeSolo
                                        value={providerData.StateIssuingLicense || ''}
                                        onChange={(event, value) =>
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    StateIssuingLicense: value || '',
                                                })
                                            )
                                        }
                                        inputValue={providerData.StateIssuingLicense || ''}
                                        onInputChange={(event, value) =>
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    StateIssuingLicense: value.toUpperCase() || '',
                                                })
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={
                                                    (!formIsValid && isNPorMD(providerData) && !states.includes(providerData.StateIssuingLicense)) ||
                                                    (providerData?.StateIssuingLicense?.length > 0 && !states.includes(providerData.StateIssuingLicense))
                                                }
                                                helperText={
                                                    (isNPorMD(providerData) && 'Required') ||
                                                    (providerData.StateIssuingLicense &&
                                                        providerData.StateIssuingLicense.length > 0 &&
                                                        !states.includes(providerData.StateIssuingLicense) &&
                                                        'Invalid')
                                                }
                                                required={isNPorMD(providerData)}
                                                label="State of License"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={providerData.IsAdmin}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                dispatch(
                                                    setProviderData({
                                                        ...providerData,
                                                        IsAdmin: event.target.checked,
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label="CHP Admin"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DialogContentText fontWeight={'bold'}>Billing Info</DialogContentText>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl fullWidth sx={{ margin: '8px 0px 4px' }}>
                                    <InputLabel error={!formIsValid && providerData.MeansOfEncounter === undefined} required id="demo-simple-select-label">
                                        Means of Encounter
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={providerData.MeansOfEncounter || ''}
                                        defaultValue={''}
                                        error={!formIsValid && providerData.MeansOfEncounter === undefined}
                                        margin="dense"
                                        label="Means of Encounter"
                                        name="MeansOfEncounter"
                                        onChange={(e) =>
                                            dispatch(
                                                setProviderData({
                                                    ...providerData,
                                                    MeansOfEncounter: e.target.value,
                                                })
                                            )
                                        }
                                    >
                                        <MenuItem disabled value={''}>
                                            Select NPI Number
                                        </MenuItem>
                                        <MenuItem value={'Personal NPI'}>Personal NPI</MenuItem>
                                        <MenuItem value={'Group NPI'}>Group NPI</MenuItem>
                                    </Select>
                                    <FormHelperText error={!formIsValid && providerData.MeansOfEncounter === undefined}>Required</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={providerData.GroupNPINumber || ''}
                                    disabled={providerData.MeansOfEncounter === 'Personal NPI'}
                                    id="group-npi-number"
                                    label="Group NPI Number"
                                    error={
                                        providerData.MeansOfEncounter === 'Group NPI' &&
                                        !formIsValid &&
                                        providerData.GroupNPINumber !== null &&
                                        providerData.GroupNPINumber?.length < 10
                                    }
                                    helperText={providerData.MeansOfEncounter === 'Group NPI' ? 'Required' : ''}
                                    fullWidth
                                    variant="outlined"
                                    name="GroupNPINumber"
                                    onChange={inputOnChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    value={providerData.NPINumber || ''}
                                    disabled={providerData.MeansOfEncounter === 'Group NPI'}
                                    id="personal-npi-number"
                                    label="Personal NPI Number"
                                    error={providerData.MeansOfEncounter === 'Personal NPI' && !formIsValid && providerData.NPINumber?.length < 10}
                                    helperText={providerData.MeansOfEncounter === 'Personal NPI' || providerData.NPINumber?.length < 10 ? 'Required' : ''}
                                    fullWidth
                                    variant="outlined"
                                    name="NPINumber"
                                    onChange={inputOnChange}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button variant="outlined" className="button-120" onClick={() => dispatch(handleClose())}>
                        Cancel
                    </Button>
                    <Button variant="contained" className="button-120" onClick={submitHandler}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProviderDialog;
