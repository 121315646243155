import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, ERROR, SUCCESS, vitalTypes } from '../utils/constants';
import axios from 'axios';
import moment from 'moment';
import { setIsLoading, setShowMessage } from './shared.slice';
import { dictionaryToArray } from '../utils/mappings';
import { gotoNextMenu, setHgba1cByMemberBasicInfo, setLipidAssessmentByMember } from './assessment.slice';
import { Member } from '../Models/Member/Member.model';
import { MemberBasicInfo } from '../Models/Member/MemberBasicInfo.model';
import { LoginSetup } from '../Models/Member/LoginSetup.model';
import { AdvanceDirective } from '../Models/Shared/AdvanceDirective.model';
import { objectToFormData } from '../utils/common';
import { getMemberByMemberId } from './memberDemographicsDetails.slice';

const memberDetailSlice = createSlice({
    name: 'memberDetail',
    initialState: {
        member: new Member(),
        memberBasicInfo: new MemberBasicInfo(),
        advancedDirective: new AdvanceDirective(),
        careCircle: [],
        caseManagerList: [],
        devices: null,
        healthPlans: [],
        insuranceTypes: [],
        life365: { ApiVersion: 2, externId: '', username: '', password: '', email: '', IsCreateAcount: true },
        loginSetup: new LoginSetup(),
        memberHealthPlans: [],
        pcpList: [],
        pharmacyList: [],
        selectedMember: null,
        selectedTab: 0,
        showLoginSetup: false,
        specialistList: [],
        specialistTypes: [],
        teams: [],
        vitalSigns: [],
        isFetching: false,
        isSaving: false,
    },
    reducers: {
        _setAdvancedDirective(state, action) {
            state.advancedDirective = action.payload || new AdvanceDirective();
        },
        _setCaseManagerList(state, action) {
            state.caseManagerList = action.payload;
        },
        _setCareCircle(state, action) {
            state.careCircle = action.payload;
        },
        _setDevices(state, action) {
            state.devices = action.payload;
        },
        _setHealthPlans(state, action) {
            state.healthPlans = action.payload;
        },
        _setInsuranceTypes(state, action) {
            state.insuranceTypes = action.payload;
        },
        _setLife365(state, action) {
            state.life365 = action.payload;
        },
        _setLoginSetup(state, action) {
            state.loginSetup = action.payload;
        },
        _setMemberHealthPlans(state, action) {
            state.memberHealthPlans = action.payload;
        },
        _setPcpList(state, action) {
            state.pcpList = action.payload;
        },
        _setPharmacyList(state, action) {
            state.pharmacyList = action.payload;
        },
        _setSelectedMember(state, action) {
            state.selectedMember = action.payload;
        },
        _setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
        _setShowLoginSetup(state, action) {
            state.showLoginSetup = action.payload;
        },
        _setSpecialistList(state, action) {
            state.specialistList = action.payload;
        },
        _setSpecialistTypes(state, action) {
            state.specialistTypes = action.payload;
        },
        _setTeams(state, action) {
            state.teams = action.payload;
        },
        _setVitalSigns(state, action) {
            state.vitalSigns = action.payload;
        },
        _setMember(state, action) {
            state.member = action.payload;
        },
        _setMemberBasicInfo(state, action) {
            state.memberBasicInfo = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
    },
});

const {
    _setAdvancedDirective,
    _setCaseManagerList,
    _setCareCircle,
    _setDevices,
    _setHealthPlans,
    _setInsuranceTypes,
    _setLife365,
    _setLoginSetup,
    _setMemberHealthPlans,
    _setPcpList,
    _setPharmacyList,
    _setSelectedMember,
    _setSelectedTab,
    _setShowLoginSetup,
    _setSpecialistList,
    _setSpecialistTypes,
    _setTeams,
    _setVitalSigns,
    _setMember,
    _setMemberBasicInfo,
    _toggleIsFetching,
    _toggleIsSaving,
} = memberDetailSlice.actions;

export const deleteCareCircle =
    (memberId: string, careGiverId: string, reasonForDelete: number): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/member/deletecaregiver?memberId=${memberId}&careGiverId=${careGiverId}&reasonForDelete=${reasonForDelete}`);
        dispatch(fetchCareCircle(getState().memberDetail.selectedMember.Id));
    };

export const deleteCaseManager =
    (memberId: string, caseManagerId: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/member/deletecasemanager?memberId=${memberId}&caseManagerId=${caseManagerId}`);
        dispatch(fetchCaseManagers(getState().memberDetail.selectedMember.Id));
    };

export const deleteLife365 =
    (externId: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/vitalsigns/deletelife365member?externId=${externId}`);
        dispatch(fetchLife365(getState().memberDetail.selectedMember.Id));
    };

export const deleteHealthPlan =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/member/deletememberhealthplan?id=${id}`);
        dispatch(fetchMemberHealthPlans(getState().memberDetail.selectedMember.Id));
    };

export const deletePcpSpecialist =
    (memberId: string, pcpSpecialistId: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/member/deletepcpandspecialist?memberId=${memberId}&pcpAndSpecialistId=${pcpSpecialistId}`);
        dispatch(fetchPcpAndSpecialists(getState().memberDetail.selectedMember.Id));
    };

export const deletePharmacy =
    (memberId: string, pharmacyId: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/member/deletepharmacy?memberId=${memberId}&pharmacyId=${pharmacyId}`);
        dispatch(fetchPharmacyList(getState().memberDetail.selectedMember.Id));
    };

export const fetchAdvancedDirective =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/member/getmemberadvancedirective`, { params: { memberId } });
        dispatch(_setAdvancedDirective(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const fetchCareCircle =
    (id: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/member/getmembercaregivers?memberId=${id}`);
        let careCircleList = (response.data || []).map((c) => {
            if (Boolean(c.DischargeSupportPerson)) {
                c.DischargeSupportPersonText = 'Yes';
            } else {
                c.DischargeSupportPersonText = 'No';
            }
            if (Boolean(c.NextOfKin)) {
                c.NextOfKinText = 'Yes';
            } else {
                c.NextOfKinText = 'No';
            }
            return c;
        });

        dispatch(_setCareCircle(careCircleList));
    };

export const fetchCaseManagers =
    (id: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/member/getcasemanagers?memberId=${id}`);
        dispatch(_setCaseManagerList(response.data));
    };

export const fetchDevices =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/vitalsigns/getmemberdevices?memberId=${id}`);
        let devices = response.data;
        if (!devices) {
            devices = {
                Id: null,
                MemberId: getState().memberDetail.selectedMember.Id,
                BpCuff: { HasDevice: false, DeliveryDate: null, RetrievalDate: null, SerialNumber: '' },
                Glucometer: { HasDevice: false, DeliveryDate: null, RetrievalDate: null, SerialNumber: '' },
                PulseOX: { HasDevice: false, DeliveryDate: null, RetrievalDate: null, SerialNumber: '' },
                Scale: { HasDevice: false, DeliveryDate: null, RetrievalDate: null, SerialNumber: '' },
                Strips: { HasDevice: false, DeliveryDate: null, RetrievalDate: null, SerialNumber: '' },
            };
        }

        for (let prop in devices) {
            if (prop === 'BpCuff') {
                devices[prop].Name = 'BP Cuff';
            } else if (prop === 'Glucometer') {
                devices[prop].Name = 'Glucometer';
            } else if (prop === 'PulseOX') {
                devices[prop].Name = 'Pulse Ox';
            } else if (prop === 'Scale') {
                devices[prop].Name = 'Scale';
            } else if (prop === 'Strips') {
                devices[prop].Name = 'Strips';
            }
        }

        dispatch(_setDevices(devices));
    };

export const fetchInsuranceTypes = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/member/getinsurancetype`);
    let insuranceTypes = [];
    for (let prop in response.data) {
        insuranceTypes.push({ value: prop, label: response.data[prop] });
    }

    dispatch(_setInsuranceTypes(insuranceTypes));
};

export const fetchLife365 =
    (id: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/vitalsigns/getlife365member?memberId=${id}`);
        dispatch(_setLife365(response.data));
    };

export const fetchMemberDemoGraphicInfo =
    (id: string, callback: Function = null): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/member/getmemberdemographicinfo?memberId=${id}`);
        if (!response.data.Phones) {
            response.data.Phones = [];
        }
        dispatch(_setSelectedMember(response.data));

        let teams = [];
        if (response.data.BHTeam) teams.push(response.data.BHTeam);
        if (response.data.CCTeam) teams.push(response.data.CCTeam);
        if (response.data.CLTeam) teams.push(response.data.CLTeam);
        if (response.data.NPTeam) teams.push(response.data.NPTeam);

        dispatch(_setTeams(teams));

        if (callback) {
            callback();
        }
    };

export const fetchHealthPlans =
    (func: Function = null): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/healthplan/getall`);
        dispatch(_setHealthPlans(response.data));
        if (func) {
            func();
        }
    };

export const fetchLoginSetup =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/member/getloginsetup?memberId=${memberId}`);
        dispatch(_setLoginSetup(response.data));
        dispatch(setIsLoading(false));
    };

export const getHealthPlanId = (memberPlan: any, healthPlans: any[], insuranceTypes: any[]): string => {
    //Look for Medicaid which we use as SMI, this is based on Kathy and Susan logic.
    //Todo match up with actual labels since there is an SMI option but doesn't factor into the logic
    const healthPlan = getHealthPlanFromMemberPlan(memberPlan, healthPlans);
    if (healthPlan && healthPlan.PlanTypes && memberPlan.InsuranceType) {
        var insuranceType = insuranceTypes.find((x) => x.value === memberPlan.InsuranceType + '');
        if (insuranceType) {
            const subPlan = healthPlan.PlanTypes.find((x) => x.OrganizationName === insuranceType.label);
            if (subPlan) {
                return subPlan.OrganizationId;
            }
        }
    }
    return healthPlan ? healthPlan.OrganizationId : null;
};

export const getHealthPlanFromMemberPlan = (memberPlan: any, healthPlans: any[]): any => {
    let healthPlan = healthPlans.find((x) => x.Id === memberPlan.HealthPlanId);
    if (!healthPlan) {
        healthPlan = healthPlans.find((x) => x.OrganizationName === memberPlan.OrganizationName);
    }
    return healthPlan;
};

export const fetchMemberHealthPlans =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/member/getmemberhealthplans?memberId=${id}`);
        if (response.data.length !== 0) {
            var healthPlans = getState().memberDetail.healthPlans;
            var insuranceTypes = getState().memberDetail.insuranceTypes;
            //Update the member health plan with the actual health plan information
            response.data.forEach((element) => {
                let healthPlan = getHealthPlanFromMemberPlan(element, healthPlans);
                element.Name = healthPlan?.Name;
                element.HealthPlanId = healthPlan?.Id;
                element.OrganizationId = getHealthPlanId(element, healthPlans, insuranceTypes);
                element.OrganizationName = healthPlan?.OrganizationName;
            });

            dispatch(_setMemberHealthPlans(response.data));
        } else {
            dispatch(_setMemberHealthPlans([]));
        }
    };

export const fetchPcpAndSpecialists =
    (id: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/member/getpcpandspecialists?memberId=${id}`);
        const specialistItems = response.data.filter((x) => x.PcpOrSpecialistType === 2);
        const pcpItems = response.data.filter((x) => x.PcpOrSpecialistType === 1);

        dispatch(_setPcpList(pcpItems));
        dispatch(_setSpecialistList(specialistItems));
    };

export const fetchPharmacyList =
    (id: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/member/getpharmacies?memberId=${id}`);
        dispatch(_setPharmacyList(response.data));
    };

export const fetchSpecialistTypes = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/member/getspecialisttype`);
    const options = dictionaryToArray(response.data);
    dispatch(_setSpecialistTypes(options));
};

export const fetchVitalsSigns =
    (id: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/vitalsigns/getvitalsignparameters?memberId=${id}`);
        for (let vital of response.data.VitalSignParameters) {
            vital.Values = [vital.Min, vital.Max];

            let type = vitalTypes.find((x) => x.type === vital.Type);
            vital.Name = type.name;
        }
        dispatch(_setVitalSigns(response.data.VitalSignParameters));
    };

export const saveCareCircle =
    (careCircle: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (careCircle.Id) {
                await axios.put(`${API_URL}/member/updatecaregiver?memberId=${getState().memberDetail.selectedMember.Id}`, careCircle);
            } else {
                await axios.put(`${API_URL}/member/addcaregiver?memberId=${getState().memberDetail.selectedMember.Id}`, careCircle);
            }
            dispatch(fetchCareCircle(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveAdvancedDirective =
    (advancedDirective: AdvanceDirective, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        try {
            let formData = new FormData();
            if (advancedDirective.File) {
                formData.append('document', advancedDirective.File, advancedDirective.File.name);
            }

            objectToFormData(advancedDirective, formData);
            const response = await (Boolean(advancedDirective.Id)
                ? axios.put(`${API_URL}/member/updateadvancedirective`, formData)
                : axios.post(`${API_URL}/member/createadvancedirective`, formData));
            dispatch(_setAdvancedDirective(response.data));
            if (callback) {
                callback(response.data);
            } else {
                dispatch(setShowMessage(true, 'Success!', SUCCESS));
            }
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
        dispatch(_toggleIsSaving(false));
    };

export const saveCaseManager =
    (caseManager: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (caseManager.Id) {
                await axios.put(`${API_URL}/member/updatecasemanager?memberId=${getState().memberDetail.selectedMember.Id}`, caseManager);
            } else {
                await axios.put(`${API_URL}/member/addcasemanager?memberId=${getState().memberDetail.selectedMember.Id}`, caseManager);
            }
            dispatch(fetchCaseManagers(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveDevices =
    (devices: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            await axios.put(`${API_URL}/vitalsigns/createorupdatememberdevices`, devices);
            dispatch(fetchDevices(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveHealthPlan =
    (healthPlan: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (healthPlan.Id) {
                await axios.put(`${API_URL}/member/updatememberhealthplan`, healthPlan);
            } else {
                await axios.put(`${API_URL}/member/insertmemberhealthplan`, healthPlan);
            }
            dispatch(fetchMemberHealthPlans(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveLife365 =
    (life365: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (life365.IsCreateAcount) {
                await axios.put(`${API_URL}/vitalsigns/createlife365member`, life365);
            } else {
                await axios.put(`${API_URL}/vitalsigns/updatelife365member`, life365);
            }

            dispatch(fetchLife365(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const getMemberById =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/member/getmemberbyid`, { params: { memberId } });
            dispatch(_setMember(response.data));
            dispatch(setLipidAssessmentByMember(response.data));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const getMemberBasicInfo =
    (memberId: string, callBack?: Function): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/member/getmemberbaseinfo`, { params: { memberId } });
            dispatch(_setMemberBasicInfo(response.data));
            dispatch(setHgba1cByMemberBasicInfo(response.data));
            if (Boolean(callBack)) {
                callBack(response.data);
            }
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveMemberBasicInfo =
    (member: any): AppThunk =>
    async (dispatch) => {
        try {
            member.DateOfBirth = moment(member.DOB).format('MM/DD/yyyy');
            await axios.put(`${API_URL}/member/updatememberbasicinfo`, member);
            dispatch(fetchMemberDemoGraphicInfo(member.Id));
            dispatch(getMemberByMemberId(member.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveMemberDemographicInfo =
    (member: any, profileFile: any): AppThunk =>
    async (dispatch) => {
        var form_data = new FormData();

        for (var key in member) {
            if (key === 'PrimaryHealthPlan' && member.PrimaryHealthPlan?.Id) {
                form_data.append('memberDemographicInfo[PrimaryHealthPlan][Id]', member.PrimaryHealthPlan.Id);
                form_data.append('memberDemographicInfo[PrimaryHealthPlan][Name]', member.PrimaryHealthPlan.Name);
            } else if (key === 'PrimaryLanguage' && member.PrimaryLanguage?.Id) {
                form_data.append('memberDemographicInfo[PrimaryLanguage][Id]', member.PrimaryLanguage.Id);
                form_data.append('memberDemographicInfo[PrimaryLanguage][Name]', member.PrimaryLanguage.Name);
            } else if (key === 'Team' && member.Team?.Id) {
                form_data.append('memberDemographicInfo[Team][Name]', member.Team.Name);
                form_data.append('memberDemographicInfo[Team][Type]', member.Team.Type);
                form_data.append('memberDemographicInfo[Team][Id]', member.Team.Id);
            } else if (key === 'DOB' && member.DOB) {
                form_data.append('memberDemographicInfo[DOB]', new Date(member.DOB).toISOString());
                form_data.append('memberDemographicInfo[DateOfBirth]', moment(new Date(member.DOB)).format('MM/DD/yyyy'));
            } else if (key === 'LastPCPVisit' && member.LastPCPVisit) {
                form_data.append('memberDemographicInfo[LastPCPVisit]', new Date(member.LastPCPVisit).toISOString());
            } else if (key === 'DateOfDelivery' && member.DateOfDelivery) {
                form_data.append('memberDemographicInfo[DateOfDelivery]', new Date(member.DateOfDelivery).toISOString());
            } else if (key === 'DateOfOnboard' && member.DateOfOnboard) {
                form_data.append('memberDemographicInfo[DateOfOnboard]', new Date(member.DateOfOnboard).toISOString());
            } else if (key === 'EndOfDateOfService' && member.EndOfDateOfService) {
                form_data.append('memberDemographicInfo[EndOfDateOfService]', new Date(member.EndOfDateOfService).toISOString());
            } else if (key === 'MeetingAddress' && member.MeetingAddress) {
                form_data.append('memberDemographicInfo[MeetingAddress][Address1]', member.MeetingAddress.Address1 ? member.MeetingAddress.Address1 : '');
                form_data.append('memberDemographicInfo[MeetingAddress][Address2]', member.MeetingAddress.Address2 ? member.MeetingAddress.Address2 : '');
                form_data.append('memberDemographicInfo[MeetingAddress][City]', member.MeetingAddress.City ? member.MeetingAddress.City : '');
                form_data.append('memberDemographicInfo[MeetingAddress][State]', member.MeetingAddress.State ? member.MeetingAddress.State : '');
                form_data.append('memberDemographicInfo[MeetingAddress][ZipCode]', member.MeetingAddress.ZipCode ? member.MeetingAddress.ZipCode : '');
                form_data.append('memberDemographicInfo[MeetingAddress][Country]', member.MeetingAddress.Country ? member.MeetingAddress.Country : '');
                form_data.append('memberDemographicInfo[MeetingAddress][Lon]', member.MeetingAddress.Lon);
                form_data.append('memberDemographicInfo[MeetingAddress][Lat]', member.MeetingAddress.Lat);
            } else if (key === 'DischargingAddress' && member.DischargingAddress) {
                form_data.append(
                    'memberDemographicInfo[DischargingAddress][Address1]',
                    member.DischargingAddress.Address1 ? member.DischargingAddress.Address1 : ''
                );
                form_data.append(
                    'memberDemographicInfo[DischargingAddress][Address2]',
                    member.DischargingAddress.Address2 ? member.DischargingAddress.Address2 : ''
                );
                form_data.append('memberDemographicInfo[DischargingAddress][City]', member.DischargingAddress.City ? member.DischargingAddress.City : '');
                form_data.append('memberDemographicInfo[DischargingAddress][State]', member.DischargingAddress.State ? member.DischargingAddress.State : '');
                form_data.append(
                    'memberDemographicInfo[DischargingAddress][ZipCode]',
                    member.DischargingAddress.ZipCode ? member.DischargingAddress.ZipCode : ''
                );
                form_data.append(
                    'memberDemographicInfo[DischargingAddress][Country]',
                    member.DischargingAddress.Country ? member.DischargingAddress.Country : ''
                );
                form_data.append('memberDemographicInfo[DischargingAddress][Lon]', member.DischargingAddress.Lon);
                form_data.append('memberDemographicInfo[DischargingAddress][Lat]', member.DischargingAddress.Lat);
            } else if (key === 'TCCProvider' && member.TCCProvider?.Id) {
                form_data.append('memberDemographicInfo[TCCProvider][Id]', member.TCCProvider.Id);
            } else if (member[key]) {
                form_data.append('memberDemographicInfo[' + key + ']', member[key]);
            }
        }

        if (profileFile) {
            form_data.append('image', profileFile);
        }
        dispatch(setIsLoading(true));
        dispatch(_toggleIsSaving(true));
        try {
            await axios.put(`${API_URL}/member/updatedemographicinfo`, form_data);
            await axios.get(`${API_URL}/member/updateveradigmmember?memberId=${member.Id}`);

            dispatch(fetchMemberDemoGraphicInfo(member.Id, () => dispatch(getMemberByMemberId(member.Id))));
            dispatch(getMemberById(member.Id));
            dispatch(
                getMemberBasicInfo(member.Id, () => {
                    dispatch(gotoNextMenu());
                })
            );
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
        dispatch(_toggleIsSaving(false));
        dispatch(setIsLoading(false));
    };

export const savePcpSpecialist =
    (pcpSpecial: any): AppThunk =>
    async (dispatch, getState) => {
        const memberId = getState().memberDetail.selectedMember.Id;
        try {
            if (pcpSpecial.Id) {
                await axios.put(`${API_URL}/member/updatepcpandspecialist?memberId=${memberId}`, pcpSpecial);
            } else {
                await axios.put(`${API_URL}/member/addpcpandspecialist?memberId=${memberId}`, pcpSpecial);
            }

            dispatch(fetchPcpAndSpecialists(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const savePharmacy =
    (pharmacy: any): AppThunk =>
    async (dispatch, getState) => {
        const memberId = getState().memberDetail.selectedMember.Id;
        try {
            if (pharmacy.Id) {
                await axios.put(`${API_URL}/member/updatepharmacy?memberId=${memberId}`, pharmacy);
            } else {
                await axios.put(`${API_URL}/member/addpharmacy?memberId=${memberId}`, pharmacy);
            }
            dispatch(fetchPharmacyList(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const saveVitalSigns =
    (vitalSigns: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            await axios.put(`${API_URL}/vitalsigns/insertvitalsignparameters`, { VitalSignParameters: vitalSigns });
            dispatch(fetchVitalsSigns(getState().memberDetail.selectedMember.Id));
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
        }
    };

export const setAdvancedDirective =
    (ad: AdvanceDirective): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAdvancedDirective(ad));
    };

export const setDevices =
    (devices: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setDevices(devices));
    };

export const setLife365 =
    (life365: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setLife365(life365));
    };

export const saveLoginSetup =
    (loginSetup: any, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        await axios.put(`${API_URL}/member/loginsetup`, loginSetup);
        dispatch(_setShowLoginSetup(false));
        dispatch(fetchMemberDemoGraphicInfo(loginSetup.MemberId));
        dispatch(setShowMessage(true, 'Success!', SUCCESS));
        dispatch(_toggleIsSaving(false));
        if (Boolean(callback)) {
            callback();
        }
    };

export const setLoginSetup =
    (loginSetup: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setLoginSetup(loginSetup));
    };

export const setShowLoginSetup =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        if (show) {
            dispatch(fetchLoginSetup(getState().memberDetail.selectedMember.Id));
        }
        dispatch(_setShowLoginSetup(show));
    };

export const setSelectedMember =
    (member: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedMember(member));
    };

export const setSelectedTab =
    (tab: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedTab(tab));
    };

export const setVitalSigns =
    (vitalSigns: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setVitalSigns(vitalSigns));
    };

export const updateNoKnownAllergies =
    (memberId: string, noKnownAllergies: boolean): AppThunk =>
    async (dispatch, getState) => {
        const memberBasicInfo = getState().memberDetail.memberBasicInfo;
        dispatch(_setMemberBasicInfo({ ...memberBasicInfo, NoKnownAllergies: noKnownAllergies }));
        axios.put(`${API_URL}/member/updatenoknownallergies`, null, { params: { memberId, noKnownAllergies } });
    };

export default memberDetailSlice.reducer;
